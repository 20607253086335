import { AppCheck, getToken } from '@angular/fire/app-check'
import { Inject, Injectable } from '@angular/core'
import { HttpInterceptor } from '@angular/common/http'
import { HttpRequest } from '@angular/common/http'
import { from, lastValueFrom, Observable } from 'rxjs'
import { HttpHandler } from '@angular/common/http'
import { HttpEvent } from '@angular/common/http'
import { Router } from '@angular/router'
import { AuthService } from '@devent/web/shared/auth'
import { ENVIRONMENT, Environment } from '@devent/web/environment'
import { getAuth } from '@angular/fire/auth'

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  token: string
  ignoreCallsStartingWith = ['assets']
  /**
   * This is a list of api's that we want to skip the interceptor for.
   * europe-  : firebase functions
   * ds/login : login (services)
   * ds/results : public results (services)
   * ds/events : public results events (services)
   */
  omitCallsIncluding = ['europe-', 'ds/login', 'ds/results', 'ds/events']
  skipInterceptor = false
  ignoreInterceptor = false
  //version = 'N/A'

  // NOTE: had to remove versionservice, since this will cause cyclic dependency, so services that are intercepted can not be used here

  constructor(
    private router: Router,
    //private authService: AuthService,
    //private readonly _ver: VersionService
    @Inject(ENVIRONMENT) private readonly _env: Environment //private readonly appCheck: AppCheck
  ) {
    console.log('-- Interceptor created')
    //_ver.version().subscribe((v) => (this.version = v.version))
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = getAuth().currentUser

    this.skipInterceptor = false
    this.omitCallsIncluding.forEach((api) => {
      // console.log('checking', api, req.url, req.url.indexOf(api))
      if (req.url.includes(api)) {
        this.skipInterceptor = true
      }
    })

    this.ignoreInterceptor = false
    this.ignoreCallsStartingWith.forEach((api) => {
      if (req.url.startsWith(api)) {
        this.ignoreInterceptor = true
      }
    })

    if (this.ignoreInterceptor) {
      console.log('ignoring -> ' + req.url)
      return next.handle(req)
    }

    // console.log('-- Intercepting and adding firebase token --', req.url)
    req = req.clone({
      headers: req.headers.set('de-client-version', this._env.version),
    })

    if (user) {
      req = req.clone({
        headers: req.headers
          .set('de-client-user-id', user.uid)
          .set('de-client-user-name', encodeURIComponent(user.displayName)),
      })
    } else {
      req = req.clone({
        headers: req.headers.set('de-client-user-id', 'anonymous'),
      })
    }

    if (this.skipInterceptor) {
      console.log('headers (skipInterceptor): ', req.headers)
      if (req.url.includes('elastic-search')) {
        return from(this.handleElastic(req, next))
      }
      return next.handle(req)
    } else {
      return from(this.handle(req, next))
    }
    /*

    if we want to redirect to login if call fails...

    this.authService.getUserIdToken().then((token) => {
      this.token = token
      if (this.token || this.skipInterceptor) {
        const tokenizedReq = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + this.token),
        })
        return next.handle(tokenizedReq).pipe(
          map((eventId: HttpEvent<any>) => {
            if (eventId instanceof HttpResponse) {
              if (eventId.status === 401) {
                //this.authService.userLoggedOut()
                this.router.navigateByUrl('core/login')
              }
            }
            return eventId
          })
        )
      } else {
        //this.authService.userLoggedOut()
        this.router.navigateByUrl('core/login')
      }
    })
    return next.handle(req) */
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = await getAuth().currentUser?.getIdToken()
    // const authToken = await this.authService.getUserIdToken()
    const authReq = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + (authToken ? authToken : 'null'),
      },
    })
    console.log('headers (authToken): ', authReq?.headers)

    return lastValueFrom(next.handle(authReq)) //.toPromise()
  }

  async handleElastic(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this._env.elastic.apiKey,
      },
    })
    console.log('elastic headers (authToken): ', authReq?.headers)

    return lastValueFrom(next.handle(authReq)) //.toPromise()
  }
}
