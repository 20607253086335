import { IDog } from './dog.interface'
import { IUser } from './user.interface'
export interface ITeam {
  id: string
  name: string
  size: string
  club: string
  clubId?: string
  huId: string
  members?: string[]
  handlers?: string[] // for different handlers of the dogs..
  dogs?: string[]
  admins?: string[]
  affiliation?: string[] //members + owner
  userId: string // owner
  userName: string // owner
  active?: boolean
  dogInfo?: {
    id: string
    name: string
    huId: string
    breed: { raid: string; name: string }
  }[]
  memberInfo?: { id: string; displayName: string }[]
  // TODO: handle change of handler, this can be set on memberInfo, but have then members and handlers (memberInfo) are not the same
}

export type ITeamLight = Pick<
  ITeam,
  | 'id'
  | 'name'
  | 'club'
  | 'huId'
  | 'size'
  | 'admins'
  | 'members'
  | 'dogs'
  | 'handlers'
  | 'userId'
  | 'userName'
  | 'active'
>
type TeamTemp = Omit<ITeam, 'members' | 'admins | dogs'>

export type TeamExpanded = TeamTemp & {
  members: IUser[]
  admins: IUser[]
  dogs: IDog[]
}
export interface ITeamDisplay {
  id?: string // same as id on ITeam (not stored)
  name?: string // same as name on ITeam (not stored)
  owner: { id?: string; displayName: string } // id same as userId on ITeam (not stored), displayName of owner
  huId?: string // same as huId on ITeam (not stored)
  members: { id: string; displayName: string }[]
  dogs: {
    id: string
    name: string
    huId: string
    breed: { raid: string; name: string }
  }[]
  club: { id: string; name: string }
}

// TODO: need to clean this up, display props owner name, members and dogs should be on the main at gotten from there in attend create
