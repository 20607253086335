// To parse this data:
//
//   import { Convert, NkkDog } from "./file";
//
//   const nkkDog = Convert.toNkkDog(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface NkkDog {
  aktivitetsSperre?: boolean
  aktivtMedlem?: boolean
  regNr: string
  doed: boolean
  navn: string
  chipId: string
  tattooNr: null
  annenIdMerking: null
  raseId: string
  rasenavn: string
  tittel: string
  fargeId: string
  fargeNavn: string
  hundId: string
  haarlagId: null
  haarlagNavn: null
  storrelseId: null
  storrelseNavn: null
  foedt: string
  doedDato: null
  doedDatoFormat: null
  kjoenn: string
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class NkkDogConvert {
  public static toNkkDog(json: string): NkkDog {
    return cast(JSON.parse(json), r('NkkDog'))
  }

  public static nkkDogToJson(value: NkkDog): string {
    return JSON.stringify(uncast(value, r('NkkDog')), null, 2)
  }
}

function invalidValue(typ: any, val: any, key: any = ''): never {
  if (key) {
    throw Error(
      `Invalid value for key "${key}". Expected type ${JSON.stringify(
        typ
      )} but got ${JSON.stringify(val)}`
    )
  }
  throw Error(
    `Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`
  )
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {}
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }))
    typ.jsonToJS = map
  }
  return typ.jsonToJS
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {}
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }))
    typ.jsToJSON = map
  }
  return typ.jsToJSON
}

function transform(val: any, typ: any, getProps: any, key: any = ''): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val
    return invalidValue(typ, val, key)
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length
    for (let i = 0; i < l; i++) {
      const typ = typs[i]
      try {
        return transform(val, typ, getProps)
      } catch (_) {}
    }
    return invalidValue(typs, val)
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val
    return invalidValue(cases, val)
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue('array', val)
    return val.map((el) => transform(el, typ, getProps))
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null
    }
    const d = new Date(val)
    if (isNaN(d.valueOf())) {
      return invalidValue('Date', val)
    }
    return d
  }

  function transformObject(
    props: { [k: string]: any },
    additional: any,
    val: any
  ): any {
    if (val === null || typeof val !== 'object' || Array.isArray(val)) {
      return invalidValue('object', val)
    }
    const result: any = {}
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key]
      const v = Object.prototype.hasOwnProperty.call(val, key)
        ? val[key]
        : undefined
      result[prop.key] = transform(v, prop.typ, getProps, prop.key)
    })
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key)
      }
    })
    return result
  }

  if (typ === 'any') return val
  if (typ === null) {
    if (val === null) return val
    return invalidValue(typ, val)
  }
  if (typ === false) return invalidValue(typ, val)
  while (typeof typ === 'object' && typ.ref !== undefined) {
    typ = typeMap[typ.ref]
  }
  if (Array.isArray(typ)) return transformEnum(typ, val)
  if (typeof typ === 'object') {
    return typ.hasOwnProperty('unionMembers')
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty('arrayItems')
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty('props')
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val)
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== 'number') return transformDate(val)
  return transformPrimitive(typ, val)
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps)
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps)
}

function a(typ: any) {
  return { arrayItems: typ }
}

function u(...typs: any[]) {
  return { unionMembers: typs }
}

function o(props: any[], additional: any) {
  return { props, additional }
}

function m(additional: any) {
  return { props: [], additional }
}

function r(name: string) {
  return { ref: name }
}

const typeMap: any = {
  NkkDog: o(
    [
      { json: 'aktivitetsSperre', js: 'aktivitetsSperre', typ: true },
      { json: 'aktivtMedlem', js: 'aktivtMedlem', typ: true },
      { json: 'regNr', js: 'regNr', typ: '' },
      { json: 'doed', js: 'doed', typ: true },
      { json: 'navn', js: 'navn', typ: '' },
      { json: 'chipId', js: 'chipId', typ: '' },
      { json: 'tattooNr', js: 'tattooNr', typ: null },
      { json: 'annenIdMerking', js: 'annenIdMerking', typ: null },
      { json: 'raseId', js: 'raseId', typ: '' },
      { json: 'rasenavn', js: 'rasenavn', typ: '' },
      { json: 'tittel', js: 'tittel', typ: '' },
      { json: 'fargeId', js: 'fargeId', typ: '' },
      { json: 'fargeNavn', js: 'fargeNavn', typ: '' },
      { json: 'hundId', js: 'hundId', typ: '' },
      { json: 'haarlagId', js: 'haarlagId', typ: null },
      { json: 'haarlagNavn', js: 'haarlagNavn', typ: null },
      { json: 'storrelseId', js: 'storrelseId', typ: null },
      { json: 'storrelseNavn', js: 'storrelseNavn', typ: null },
      { json: 'foedt', js: 'foedt', typ: '' },
      { json: 'doedDato', js: 'doedDato', typ: null },
      { json: 'doedDatoFormat', js: 'doedDatoFormat', typ: null },
      { json: 'kjoenn', js: 'kjoenn', typ: '' },
    ],
    false
  ),
}
