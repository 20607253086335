// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// cloudinary->  key:234727945157654 secret: U1or9qO7StwRMEKxbvXPnbLdHuE
import projectInfo from '../../../../package.json'

// appcheck debug token D4FBF500-1F68-478C-826A-B5946F183FF8

export const environment = {
  version: projectInfo.version,
  name: projectInfo.name,
  cloudName: 'kjetilhp',
  uploadPreset: 'nhm2kddo',
  staging: false,
  production: true,
  dev: true,
  emulators: false,
  beta: false,
  firebase: {
    apiKey: 'AIzaSyBZMWNDW9OMZF5uDwz9PUTsajTagxVPyl8',
    authDomain: 'devent-dev.firebaseapp.com',
    databaseURL: 'https://devent-dev-db.europe-west1.firebasedatabase.app',
    projectId: 'devent-dev',
    storageBucket: 'devent-dev.appspot.com',
    messagingSenderId: '273754521464',
    appId: '1:273754521464:web:83a393d873709d8f06128d',
    measurementId: 'G-NHJVZ6DXW5',
  },
  vapidKey:
    'BM5R6OZcV6jekyB-5cz9cOlVTrCQvxmWJtGOr_XTsfvBbUTDZftatHNr2igY_hAZ3E8AeydUwdBNr74VPW9jogM',
  // private fcm key : s_Wx7dBDwcWavUJhiM_qNG1jFgf25NSRIhr1FiENU9o
  recaptcha3SiteKey: '6LcakSAeAAAAALzExK4FKWJYv8-qkgTP8W8agsdO',
  //recaptcha3SecretKey: '6LcakSAeAAAAAPrFNaM-bM_aqXbB7yimjuqrRzLY',
  recaptcha3SiteKeyEnterprise: '6LffHR4eAAAAAOBuBQ2s4cQEvejHbJ4zno4m5kvl',
  functionsUrl: 'https://europe-west3-devent-dev.cloudfunctions.net/',
  servicesUrl: 'https://devapi.devent.no/',
  merchantSerialNumber: '230261',
  paymentFallBack: 'https://devlocal.devent.no/payment/',
  stripeKey:
    'pk_test_51IuMJhElZhYFeePPzHXCQRfleefU1yCelAqEz4PrHivnseXvVOZ1pAkJ7b5aHyjdrPssPbpAG6GezISUR4DYIZku00lkbrxcyP',
  elastic: {
    elasticAppSearchConfig: {
      apiKey: 'search-d83im69698a9po93upprari2',
      baseUrl: 'https://devappsearch.devent.no',
    },
  },
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error' // Included with Angular CLI.
