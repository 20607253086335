import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { ENVIRONMENT, Environment } from '@devent/web/environment'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImRlIiwic3ViIjozLCJpYXQiOjE2MTA2MDgzNTUsImV4cCI6MTYxMDY0NDM1NX0.5_aSlZ3paNo6iCdX7viNOsD2gjK_e9Vp3XX_LEFonW0',
  }),
}

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  constructor(
    private readonly http: HttpClient,
    @Inject(ENVIRONMENT) private readonly environment: Environment
  ) {}

  // TODO: flytt dette til firebase crypt function
  login() {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/auth/login',
        {
          username: 'de',
          password: 'verysecret',
        },
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public testStripe(): Observable<any> {
    return this.http
      .get(this.environment.servicesUrl + 'ds/status/stripe', httpOptions)
      .pipe(catchError(this.handleError))
  }
  public getExternalEvents(text: string): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/nkk/search/events',
        { text },
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public stripeCheckout(orderId: string): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/stripe-payment/checkout/' + orderId,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public getJudges(): Observable<any> {
    return this.http
      .get(this.environment.servicesUrl + 'ds/results/judges', httpOptions)
      .pipe(catchError(this.handleError))
  }

  getStatisticsStarts(year: string, type: string) {
    return this.http
      .get(
        this.environment.servicesUrl +
          'ds/results/statistics/starts/' +
          year +
          '/' +
          type,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  getStatisticsJudges(year: string) {
    return this.http
      .get(
        this.environment.servicesUrl + 'ds/results/statistics/judges/' + year,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  getStatisticsHosts(year: string) {
    return this.http
      .get(
        this.environment.servicesUrl + 'ds/results/statistics/hosts/' + year,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public getProfile(): Observable<any> {
    return this.http
      .get(this.environment.servicesUrl + 'ds/profile', httpOptions)
      .pipe(catchError(this.handleError))
  }

  public getYears(): Observable<any> {
    return this.http
      .get(this.environment.servicesUrl + 'ds/results/years', httpOptions)
      .pipe(catchError(this.handleError))
  }

  public getEvents(from, to): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/events/grouped',
        { start: from, stop: to },
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  getEventsByIds(ids: string): Observable<any> {
    return this.http
      .get(this.environment.servicesUrl + 'ds/events/ids/' + ids, httpOptions)
      .pipe(catchError(this.handleError))
  }

  getEventNkkDetail(detailId: string): Observable<any> {
    return this.http
      .get(
        this.environment.servicesUrl + 'ds/dog/eventdetail/' + detailId,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }
  getResultDetails(ids: string[]): Observable<any> {
    return this.http
      .get(
        this.environment.servicesUrl +
          'ds/results/ids/' +
          encodeURIComponent(ids.join(';')),
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public getEvent(eventId): Observable<any> {
    return this.http
      .get(
        this.environment.servicesUrl + 'ds/events/event/' + eventId,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public getResultsForEvent(eventId): Observable<any> {
    return this.http
      .get(this.environment.servicesUrl + 'ds/results/' + eventId, httpOptions)
      .pipe(catchError(this.handleError))
  }

  public getEventsForGroup(groupId): Observable<any> {
    return this.http
      .get(
        this.environment.servicesUrl + 'ds/events/group/' + groupId,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public getDogOfYear(year, level, size, type): Observable<any> {
    return this.http
      .get(
        this.environment.servicesUrl +
          `ds/results/doy/${year}/${level}/${size}/${type}`,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  /* public fetchDogResults(dogId): Observable<any> {
    return this.http
      .get(this.environment.servicesUrl + 'ds/elastic/results/' + dogId, httpOptions)
      .pipe(catchError(this.handleError))
  } */

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message)
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`,
        error
      )

      console.error(JSON.stringify(error))
    }
    // return an observable with a user-facing error message
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    )
  }
}
