import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AppComponent } from './app.component'

const routes: Routes = [
  {
    path: 'test',
    outlet: 'primary',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./tester/tester.component').then((m) => m.TesterComponentModule),
  },
  {
    path: 'login',
    outlet: 'primary',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./login.component').then((m) => m.LoginComponentModule),
  },
  {
    path: 'messaging',
    outlet: 'primary',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./messaging.component').then((m) => m.MessagingComponentModule),
  },
  {
    path: 'public',
    outlet: 'primary',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('@devent/web/public/feature/public-shell').then(
        (m) => m.WebPublicFeaturePublicShellModule
      ),
  },
  {
    path: 'client',
    outlet: 'primary',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('@devent/web/client/feature/client-shell').then(
        (m) => m.WebClientFeatureClientShellModule
      ),
  },
  /*
  {
    path: 'eventadmin',
    outlet: 'primary',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('@devent/web/event-adm/feature/event-adm-shell').then(
        (m) => m.WebEventAdmFeatureEventAdmShellModule
      ),
  },
  {
    path: 'event',
    outlet: 'primary',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('@devent/web/event/feature/event-shell').then(
        (m) => m.WebEventFeatureEventShellModule
      ),
  }, */
  {
    path: '',
    outlet: 'primary',
    pathMatch: 'prefix',
    redirectTo: '/login',
    //component: AppComponent,
  },
]

// Handle index.html request on Cloud Functions
if (typeof process !== 'undefined' && process.env?.['FUNCTION_NAME']) {
  routes.push({
    path: 'index.html',
    component: AppComponent,
    outlet: 'primary',
    pathMatch: 'full',
  })
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {
    console.log('AppRoutingModule.ctor')
  }
}
