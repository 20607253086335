/**
 * NOTE! the order her is important, lesser roles can be set by higher
 * */
export enum EventRole {
  EVENT_WORKER = 'event_worker',
  EVENT_ADMIN = 'event_admin',
  CLUB_ADMIN = 'club_admin',
  EVENT_OWNER = 'event_owner',
  CLUB_OWNER = 'club_owner',
  EVENT_WORKER_AUTO = 'event_worker_auto',
}
export enum SystemRole {
  HANDLER = 'handler',
  JUDGE = 'judge',
  USER = 'user',
  ADMIN = 'admin',
}
