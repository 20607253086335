import { IAttendant } from './attendant.interface'
export interface IPayment {
  id: string
  userId: string
  userName: string | null
  userEmail: string | null
  amount: number
  mobileNumber: number
  customer: string

  provider?: 'vipps' | 'stripe' | 'manual'
  version?: number

  vippsStatus?: string
  deventStatus?: string
  sub?: string // sub to use for getting VippsLogin user data after payment
  transactionText: string
  transactionDescription: string
  transactionInfo?: { status: string; amount?: number; timeStamp?: string }
  errorInfo?: {
    errorCode?: string
    errorGroup?: string
    errorMessage?: string
    contextId?: string
  }
  transactionLogHistory?: Array<{
    timeStamp: string
    amount: number
    transactionText: string
    requestId: string
    operationSuccess: boolean
    operation: string
    transactionId: string
  }>
  transactionSummary?: {
    capturedAmount: number
    remainingAmountToRefund: number
    bankIdentificationNumber: number
    remainingAmountToCapture: number
    refundedAmount: number
  }
  timeStamp?: string // ISO format
  orderId?: string
  merchantSerialNumber?: string
  emailConfirmationSent: boolean

  competitionInfo?: {
    eventId: string
    competitionId: string
    handlerId?: string
    dogId?: string
    teamId?: string
  }[] // array with objects of { eventId, competitionId }
  attendanceInfo?: string[] // array with attendanceIds

  hasSale?: boolean
  attendanceSaleInfo?: {
    attendanceId: string
    soldToUserId?: string
    soldToUserName?: string
  }[] // array with attendanceIds

  attendances?: IAttendant[]
}

export interface VippsTransaction {
  amount: number
  orderId: string
  timeStamp?: string // ISO format
  transactionText: string
  scope: string
  additionalData: string[]
}

export enum VIPPS_CALLBACK_STATES {
  RESERVED = 'RESERVED', //	Payment reserved, by user accepting the payment in Vipps
  SALE = 'SALE', //	Payment captured with direct capture, by merchant (after RESERVED)
  RESERVE_FAILED = 'RESERVE_FAILED', //	Reserve failed because of insufficient funds, invalid card, or similar
  SALE_FAILED = 'SALE_FAILED', //	Direct failed because of insufficient funds, invalid card, or similar
  CANCELLED = 'CANCELLED', //	Payment canceled, by user in Vipps
  REJECTED = 'REJECTED', //	User did not act on the payment (timeout, etc)
}

// Disse kodene virker ikke konsistente fra Vipps
// har lagt til noen ekstra for vår egen del når ting initieres
export enum VIPPS_TRANSACTION_INFO_STATES {
  INIT_CLIENT = 'INIT_CLIENT',
  INIT_SUCCESS = 'INIT_SUCCESS',
  INIT_FAILED = 'INIT_FAILED',
  NOT_FOUND = 'NOT_FOUND', // IF ORDER NOT FOUND IN VIPPS, candidate for removal

  //ERROR = 'ERROR',

  RESERVED = 'RESERVED', //	Payment reserved, by user accepting the payment in Vipps
  CAPTURED = 'Captured', //	Payment captured, by merchant (after RESERVE)
  CANCELLED = 'Cancelled',
  REJECTED = 'REJECTED',
}

export enum VIPPS_STATUS_STATES {
  // custom states
  INITIATE_ERROR = 'INITIATE_ERROR',
  INITIATE_CLIENT = 'INITIATE_CLIENT',

  NOT_FOUND = 'NOT_FOUND',

  // from vipps tx log history
  INITIATE = 'INITIATE', //	Payment initiated, by merchant
  RESERVE = 'RESERVE', //	Payment reserved, by user accepting the payment in Vipps
  CAPTURE = 'CAPTURE', //	Payment captured, by merchant (after RESERVE)
  REFUND = 'REFUND', //	Payment refunded, by merchant (after CAPTURE)
  CANCEL = 'CANCEL', //	Payment canceled, by user in Vipps
  SALE = 'SALE', //	Payment captured with direct capture, by merchant
  VOID = 'VOID', //	Payment canceled, by merchant

  MANUAL = 'MANUAL', //	When using vipps number as payment, a manual process
}

//INITIATE, RESERVE, SALE, CAPTURE, REFUND, CANCEL, VOID

export enum DEVENT_STATES {
  CART = 'CART',
  PENDING = 'PENDING',
  DONE = 'DONE',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  REFUNDED = 'REFUNDED',
}
