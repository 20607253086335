import {
  APP_BASE_HREF,
  PlatformLocation,
  registerLocaleData,
} from '@angular/common'
import { LOCALE_ID, NgModule } from '@angular/core'
import { ClarityModule } from '@clr/angular'
import { ClrAddonsModule } from '@porscheinformatik/clr-addons'
import { TuiMobileCalendarModule } from '@taiga-ui/addon-mobile'
import { TuiLetModule } from '@taiga-ui/cdk'
import {
  TuiDialogModule,
  TuiHostedDropdownModule,
  TuiButtonModule,
} from '@taiga-ui/core'
import { TuiTabsModule } from '@taiga-ui/kit'
import { TranslocoRootModule } from './transloco-root-module'

import localeNO from '@angular/common/locales/nb'
import localeNoExtra from '@angular/common/locales/extra/nb'
registerLocaleData(localeNO, 'nb-NO', localeNoExtra)

@NgModule({
  declarations: [],
  imports: [
    ClarityModule,
    ClrAddonsModule,
    TranslocoRootModule,
    TuiDialogModule,
    TuiTabsModule,
    TuiHostedDropdownModule,
    TuiButtonModule,
    TuiLetModule,
    TuiMobileCalendarModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    { provide: Window, useValue: window },
    { provide: LOCALE_ID, useValue: 'nb-NO' },
  ],
  bootstrap: [],
})
export class AppCommonModule {
  constructor() {
    console.log('AppCommonModule.ctor')
  }
}
