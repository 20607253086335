import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(public snackBar: ToastrService) {}

  msg(message: string) {
    this.snackBar.info(message)
  }
}
