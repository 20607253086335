import { IDog } from '../dog.interface'
import { NkkDog } from '../nkk/nkk-dog'

export const dogConverter = (nd: NkkDog): Partial<IDog> => {
  const dog: Partial<IDog> = {
    fullName: nd.navn,
    dateOfBirth: nd.foedt,
    huId: nd.regNr,
    color: nd.fargeNavn,
    breed: { raid: nd.raseId, name: nd.rasenavn },
    gender: nd.kjoenn.substring(0, 1),
    blockedNkk: nd.aktivitetsSperre,
    activeMember: nd.aktivtMedlem,
    chipId: nd.chipId,
    title: nd.tittel,
    nkkDogId: nd.hundId,
    deceased: nd.doed,
    nkkVerified: true,
  }

  return dog
}
