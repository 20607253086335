import { catchError, retry, shareReplay } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EMPTY } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private http: HttpClient) {}

  getProfilePicture(url) {
    return this.http.get(url, { responseType: 'blob' })
  }

  checkPictureUrl(url) {
    console.log('checking ', url)
    return this.http.get(url, { responseType: 'blob' }).pipe(
      retry(3),
      catchError((err) => {
        console.log('waiting for image conversion', err)
        return EMPTY
      }),
      shareReplay()
    )
  }
}

/*
retry(3),
      catchError((err) => {
        console.log('waiting for image conversion', err)
        return EMPTY
      }),
      shareReplay()

*/
