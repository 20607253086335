import {
  DEVENT_STATES,
  IEvent,
  IRing,
  IRun,
  PaymentStatus,
} from '@devent/shared-data-access-models'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Injectable } from '@angular/core'
import { map, switchMap, tap, mergeMap, takeLast, take } from 'rxjs/operators'
import { IAttendant } from '@devent/shared-data-access-models'
import { ICompetition } from '@devent/shared-data-access-models'
import { forkJoin, Observable, of, BehaviorSubject, combineLatest } from 'rxjs'
import { ToastrService } from 'ngx-toastr'

@Injectable({
  providedIn: 'root',
})
export class DeventEventService {
  event$ = new BehaviorSubject<IEvent>(null)
  eventId$ = new BehaviorSubject<string>('')

  eventPath = 'events/'
  compPath = `events/${this.eventId$.value}/competitions`

  constructor(
    private readonly db: AngularFirestore,
    private readonly _toastr: ToastrService
  ) {}

  public attendancePath(att: Partial<IAttendant>) {
    return `events/${att.eventId}/competitions/${att.competitionId}/attendance/${att.id}`
  }

  public runPath(att: Partial<IAttendant>) {
    return `events/${att.eventId}/competitions/${att.competitionId}/attendance/${att.id}/runs`
  }

  public saveRun(run: IRun) {
    this.db
      .doc<IRun>(
        this.runPath({
          id: run.attendantId,
          competitionId: run.competitionId,
          eventId: run.eventId,
        }) +
          '/' +
          run.id
      )
      .set(run, { merge: true })
      .then()
  }

  public deleteRun(run: IRun) {
    this.db
      .doc<IRun>(
        this.runPath({
          id: run.attendantId,
          competitionId: run.competitionId,
          eventId: run.eventId,
        }) +
          '/' +
          run.id
      )
      .delete()
      .then()
  }

  async deleteRunAsync(run: IRun) {
    return this.db
      .doc<IRun>(
        this.runPath({
          id: run.attendantId,
          competitionId: run.competitionId,
          eventId: run.eventId,
        }) +
          '/' +
          run.id
      )
      .delete()
  }

  async getRunsForAttendant(att: Partial<IAttendant>) {
    return await this.db.collection<IRun>(this.runPath(att)).get().toPromise()
  }

  async getRunsForCompetition(comp: Partial<ICompetition>) {
    return await this.db
      .collectionGroup<IRun>('runs', (ref) =>
        ref.where('competitionId', '==', comp.id)
      )
      .get()
      .toPromise()
  }

  async getRunsForEvent(event: Partial<IEvent>) {
    return await this.db
      .collectionGroup<IRun>('runs', (ref) =>
        ref.where('eventId', '==', event.id)
      )
      .get()
      .toPromise()
  }

  selectRunsForEvent(event: Partial<IEvent>) {
    return this.db
      .collectionGroup<IRun>('runs', (ref) =>
        ref.where('eventId', '==', event.id)
      )
      .valueChanges()
  }
  selectRunsForCompetition(comp: Partial<ICompetition>) {
    return this.db
      .collectionGroup<IRun>('runs', (ref) =>
        ref.where('competitionId', '==', comp.id)
      )
      .valueChanges()
  }

  async getEvent(id) {
    return await this.db
      .doc<IEvent>(this.eventPath + id)
      .get()
      .toPromise()
  }

  async getEventDeep(id) {
    return combineLatest([
      this.db.doc<IEvent>(this.eventPath + id).get(),
      this.db
        .collection<ICompetition>(this.eventPath + id + '/competitions')
        .get(),
      this.db.collection<IRing>(this.eventPath + id + '/rings').get(),
      this.db
        .collectionGroup<IAttendant>('attendance', (ref) =>
          ref.where('eventId', '==', id)
        )
        .get(),
    ])
      .pipe(
        map(([event, comps, rings, attendants]) => {
          const e = event.data()
          e.rings = rings.docs.map((r) => r.data())
          e.competitions = comps.docs.map((r) => r.data())
          e.allAttendants = attendants.docs.map((r) => r.data())
          e.competitions.forEach(
            (c) =>
              (c.attendants = e.allAttendants.filter(
                (a) => a.competitionId === c.id && a.eventId === e.id
              ))
          )
          return e
        })
      )
      .toPromise()
  }

  selectEvent(id): Observable<IEvent> {
    return this.db.doc<IEvent>(this.eventPath + id).valueChanges()
  }

  selectEventDeep(id): Observable<IEvent> {
    //stackoverflow.com/questions/48288861/rxjs-resolve-an-array-of-observable-and-merge-result
    console.log('eventId id to get', id)
    return this.db
      .doc<IEvent>(this.eventPath + id)
      .valueChanges()
      .pipe(
        tap((t) => console.log(t.id)),
        take(1),
        mergeMap((e) =>
          combineLatest([
            of(e),
            this.selectCompetitionsForEvent(e.id),
            this.selectAttendantsForEvent(e.id),
            this.selectRingsForEvent(e.id),
          ])
        ),
        map(([e, comps, atts, rings]) => {
          console.log(e)
          e.rings = rings
          e.competitions = comps
          e.allAttendants = atts
          e.competitions.forEach(
            (c) =>
              (c.attendants = atts.filter(
                (a) => a.competitionId === c.id && a.eventId === e.id
              ))
          )

          return e
        }),
        take(1)

        /*switchMap((eventId) => this.getCompetitions(eventId.id)),
        mergeMap((comps) => {
          console.log('comps of ' + id, comps)
          return comps.map((item) => {
            this.getAttendantsForEvent(item.eventId)
          })
        })*/
      )
  }

  selectCompetitionsForEvent(eventId) {
    return this.db
      .collection<ICompetition>(
        this.eventPath + eventId + '/competitions',
        (ref) =>
          ref
            .where('eventId', '==', eventId)
            .orderBy('day')
            .orderBy('sortOrder')
      )
      .valueChanges()
  }

  selectRingsForEvent(eventId) {
    return this.db
      .collection<IRing>(this.eventPath + eventId + '/rings', (ref) =>
        ref.orderBy('sortOrder')
      )
      .valueChanges()
  }

  selectAttendantsForEvent(eventId) {
    return this.db
      .collectionGroup<IAttendant>('attendance', (ref) =>
        ref.where('eventId', '==', eventId)
      )
      .valueChanges()
  }

  selectPaidAttendantsForEvent(eventId) {
    return this.db
      .collectionGroup<IAttendant>(
        'attendance',
        (ref) =>
          ref
            .where('eventId', '==', eventId)
            .where('paymentStatus', '==', DEVENT_STATES.DONE) //.where('paymentStatusFromProvider', 'in', [PaymentStatus.CAPTURED, PaymentStatus.RESERVED])
      )
      .valueChanges()
  }

  selectAttendantsForCompetition(comp: Partial<ICompetition>) {
    const path = `events/${comp.eventId}/competitions/${comp.id}/attendance`
    return this.db
      .collection<IAttendant>(path, (ref) => ref.orderBy('startNumber'))
      .valueChanges()
  }

  updateCompetition(c: Partial<ICompetition>) {
    if (!c.eventId || !c.id) {
      throw new Error('Can not update competition without id/eventId')
    } else {
      this.db
        .doc(this.eventPath + c.eventId + '/competitions/' + c.id)
        .update(c)
        .then(() => this._toastr.info('Konkurranse oppdatert'))
    }
  }
  upsertCompetition(c: Partial<ICompetition>) {
    if (!c.eventId) {
      throw new Error('Can not update competition without eventId')
    } else {
      if (!c.id) {
        c.id = this.db.createId()
      }
      this.db
        .doc(this.eventPath + c.eventId + '/competitions/' + c.id)
        .set(c, { merge: true })
        .then(() => this._toastr.info('Konkurranse oppdatert'))
    }
  }

  upsertEvent(e: Partial<IEvent> | IEvent) {
    if (!e.id) {
      e.id = this.db.createId()
    }
    this.db
      .doc(this.eventPath + e.id)
      .set(e, { merge: true })
      .then(() => this._toastr.info('Stevne oppdatert'))
  }

  updateCompetitionV2(c: Partial<ICompetition>): Promise<void> {
    if (c.eventId && c.id) {
      return this.db
        .doc(this.eventPath + c.eventId + '/competitions/' + c.id)
        .set(c, { merge: true })
      //.update(c)
    }
    return Promise.reject(new Error('No eventId or compId'))
  }

  async updateAttendants(atts: Partial<IAttendant>[]) {
    const batch = this.db.firestore.batch()
    for (const a of atts) {
      const ref = this.db.doc(
        `events/${a.eventId}/competitions/${a.competitionId}/attendance/${a.id}`
      ).ref
      batch.update(ref, a)
    }
    return batch.commit()
  }

  updateAttendant(a: IAttendant) {
    return this.db
      .doc(
        `events/${a.eventId}/competitions/${a.competitionId}/attendance/${a.id}`
      )
      .update(a)
  }

  deleteAttendants(atts: IAttendant[]) {
    const batch = this.db.firestore.batch()
    for (const a of atts) {
      const ref = this.db.doc(
        `events/${a.eventId}/competitions/${a.competitionId}/attendance/${a.id}`
      ).ref
      batch.delete(ref)
    }
    batch.commit().then(() => console.log(atts.length + ' attendants deleted'))
  }

  deleteAttendant(a: IAttendant) {
    return this.db
      .doc(
        `events/${a.eventId}/competitions/${a.competitionId}/attendance/${a.id}`
      )
      .delete()
  }

  saveRuns(list: IRun[]) {
    const batch = this.db.firestore.batch()
    list.forEach((run) => {
      const ref = this.db.firestore.doc(
        this.runPath({
          id: run.attendantId,
          competitionId: run.competitionId,
          eventId: run.eventId,
        }) +
          '/' +
          run.id
      )
      batch.update(ref, run)
    })
    batch.commit().then(() => console.log('All runs updated'))
  }
}
