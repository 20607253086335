import { IAttendant } from './attendant.interface'

export interface IOrder {
  id: string
  userId: string
  userName: string | null
  userEmail: string | null
  userPhone: string | null
  amount: number
  balance?: number
  paymentProvider?: PaymentProvider
  paymentProviderMetadata?: any | null
  metadata?: any
  paymentStatus?: PaymentStatus
  orderStatus?: OrderStatus
  transactionText: string
  transactionDescription: string

  competitionInfo?: {
    eventId: string
    competitionId: string
    handlerId?: string
    dogId?: string
    teamId?: string
  }[]

  attendanceInfo?: string[] // array with attendanceIds, deprecated, use attendanceIds

  // TODO: should be removed - used in receipt for view data
  attendances?: IAttendant[] // just for view data

  attendanceIds?: string[]
  eventIds?: string[]
  competitionIds?: string[]

  hasSale?: boolean
  attendanceSaleInfo?: {
    attendanceId: string
    soldToUserId?: string
    soldToUserName?: string
  }[]

  createdAt: string
  updatedAt: string | null
  checkedAt: string | null
  archivedAt: string | null

  orderLog?: { timeStamp: string; text: string }[]

  version: number
}

export enum PaymentStatus {
  PENDING = 'PENDING', // order created on client, started the payment process
  RESERVED = 'RESERVED', // vipps reserved and stripe acknowledged(?)
  FAILED = 'FAILED', // payment failed during payment process (rejected, cancelled, timedout etc)
  CAPTURED = 'CAPTURED', // payment is captured (vipps)
  CANCELED = 'CANCELED', // order is cancelled by us before capture
  REFUNDED = 'REFUNDED', // order is refunded as a whole
  REFUNDED_PARTIAL = 'REFUNDED_PARTIAL', // order have partially been refunded
}
export enum OrderStatus {
  PENDING = 'PENDING',
  DONE = 'DONE',
  ARCHIVED = 'ARCHIVED',
}
export enum PaymentProvider {
  VIPPS = 'VIPPS',
  STRIPE = 'STRIPE',
  MANUAL = 'MANUAL',
}

abstract class BasicOrder implements IOrder {
  amount: number
  archivedAt: string | null
  checkedAt: string | null
  createdAt: string
  id: string
  transactionDescription: string
  transactionText: string
  updatedAt: string | null
  userEmail: string | null
  userId: string
  userName: string | null
  userPhone: string | null
  version: number
}

export class Order extends BasicOrder {}
