<tui-root>
  <clr-main-container>
    <ng-container *ngIf="systemAlert$ | async as systemAlert">
      <clr-alerts *ngIf="systemAlert?.message">
        <clr-alert
          [clrAlertAppLevel]="true"
          [clrAlertClosable]="false"
          clrAlertType="danger"
          ><clr-alert-item>
            <div class="alert-text">{{ systemAlert.message }}</div>
          </clr-alert-item>
        </clr-alert>
      </clr-alerts> </ng-container
    ><ng-container *ngIf="systemInfo$ | async as systemInfo">
      <clr-alerts *ngIf="systemInfo?.message">
        <clr-alert
          [clrAlertAppLevel]="true"
          [clrAlertClosable]="false"
          clrAlertType="info"
          ><clr-alert-item>
            <div class="alert-text">{{ systemInfo.message }}</div>
          </clr-alert-item>
        </clr-alert>
      </clr-alerts>
    </ng-container>
    <clr-header class="header header-5">
      <div class="branding">
        <a [href]="hrefBase" class="nav-link">
          <object
            data="assets/devent-logo.svg"
            width="45"
            height="45"
            alt="logo"
            title="logo"
          ></object>
        </a>
      </div>
      <!-- actions to the right -->
      <div class="header-actions" *ngIf="true; else signedInTemplate">
        <a routerLink="/payment" class="nav-link nav-icon">
          <cds-icon shape="shopping-cart"></cds-icon>
        </a>
        <a routerLink="/client/home/profile" class="nav-link nav-icon"
          ><cds-icon shape="user" solid="true"></cds-icon
        ></a>
      </div>
      <ng-template #signedInTemplate>
        <div class="header-actions">
          <span tippy="Vipps Login">
            <a href="{{ vippsLoginUrl }}">
              <img
                src="assets/vipps-smile-orange.png"
                width="30px"
                style="margin-top: 18px; border-radius: 5px"
                alt="vipps"
              />
            </a>
          </span>
          <a
            tippy="Logg inn / registrer alternativer"
            class="nav-link nav-text"
            [routerLink]="['signin']"
            >Logg inn</a
          >
        </div>
      </ng-template>
    </clr-header>

    <ng-container *ngIf="applicationInfo$ | async as applicationInfo">
      <clr-alerts *ngIf="applicationInfo?.message">
        <clr-alert
          [clrAlertAppLevel]="false"
          [clrAlertClosable]="true"
          clrAlertType="info"
          ><clr-alert-item>
            <div class="alert-text">{{ applicationInfo.message }}</div>
          </clr-alert-item>
        </clr-alert>
      </clr-alerts>
    </ng-container>

    <router-outlet></router-outlet>
  </clr-main-container>
  <div class="footer">
    <div class="footer_version">
      <p class="p7">versjon &nbsp;{{ VERSION }}</p>
    </div>
  </div>

  <ng-container ngProjectAs="tuiOverDialogs">
    <!-- Content over dialogs-->
  </ng-container>
  <ng-container ngProjectAs="tuiOverNotifications">
    <!-- Content over notifications -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverPortals">
    <!-- Content over dropdowns -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverHints">
    <!-- Content over hints -->
  </ng-container>
</tui-root>
