// To parse this data:
//
//   import { Convert, NkkJudge } from "./file";
//
//   const nkkJudge = Convert.toNkkJudge(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface NkkJudge {
  id: string
  number: number
  firstName: string
  middleName: null
  lastName: string
  address1: string
  address2: string
  address3: string
  postalCode: string
  city: string
  countryIsoCode: string
  email: string
  privatePhone: string
  workPhone: string
  mobilePhone: string
  born: null
  authorizedByCountryIsoCode: null
  writtenPermisionRequired: boolean
  nationalJudge: boolean
  spokenLanguages: any[]
  breedAuthorisations: any[]
  breedGroupAuthorisations: any[]
  foreignKeys: ForeignKey[]
  removed: boolean
  name: string
  address: string
}

export interface ForeignKey {
  source: string
  value: string
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class NkkJudgeConvert {
  public static toNkkJudge(json: string): NkkJudge {
    return cast(JSON.parse(json), r('NkkJudge'))
  }

  public static nkkJudgeToJson(value: NkkJudge): string {
    return JSON.stringify(uncast(value, r('NkkJudge')), null, 2)
  }
}

function invalidValue(typ: any, val: any, key: any = ''): never {
  if (key) {
    throw Error(
      `Invalid value for key "${key}". Expected type ${JSON.stringify(
        typ
      )} but got ${JSON.stringify(val)}`
    )
  }
  throw Error(
    `Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`
  )
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {}
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }))
    typ.jsonToJS = map
  }
  return typ.jsonToJS
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {}
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }))
    typ.jsToJSON = map
  }
  return typ.jsToJSON
}

function transform(val: any, typ: any, getProps: any, key: any = ''): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val
    return invalidValue(typ, val, key)
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length
    for (let i = 0; i < l; i++) {
      const typ = typs[i]
      try {
        return transform(val, typ, getProps)
      } catch (_) {}
    }
    return invalidValue(typs, val)
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val
    return invalidValue(cases, val)
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue('array', val)
    return val.map((el) => transform(el, typ, getProps))
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null
    }
    const d = new Date(val)
    if (isNaN(d.valueOf())) {
      return invalidValue('Date', val)
    }
    return d
  }

  function transformObject(
    props: { [k: string]: any },
    additional: any,
    val: any
  ): any {
    if (val === null || typeof val !== 'object' || Array.isArray(val)) {
      return invalidValue('object', val)
    }
    const result: any = {}
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key]
      const v = Object.prototype.hasOwnProperty.call(val, key)
        ? val[key]
        : undefined
      result[prop.key] = transform(v, prop.typ, getProps, prop.key)
    })
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key)
      }
    })
    return result
  }

  if (typ === 'any') return val
  if (typ === null) {
    if (val === null) return val
    return invalidValue(typ, val)
  }
  if (typ === false) return invalidValue(typ, val)
  while (typeof typ === 'object' && typ.ref !== undefined) {
    typ = typeMap[typ.ref]
  }
  if (Array.isArray(typ)) return transformEnum(typ, val)
  if (typeof typ === 'object') {
    return typ.hasOwnProperty('unionMembers')
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty('arrayItems')
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty('props')
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val)
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== 'number') return transformDate(val)
  return transformPrimitive(typ, val)
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps)
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps)
}

function a(typ: any) {
  return { arrayItems: typ }
}

function u(...typs: any[]) {
  return { unionMembers: typs }
}

function o(props: any[], additional: any) {
  return { props, additional }
}

function m(additional: any) {
  return { props: [], additional }
}

function r(name: string) {
  return { ref: name }
}

const typeMap: any = {
  NkkJudge: o(
    [
      { json: 'id', js: 'id', typ: '' },
      { json: 'number', js: 'number', typ: 0 },
      { json: 'firstName', js: 'firstName', typ: '' },
      { json: 'middleName', js: 'middleName', typ: null },
      { json: 'lastName', js: 'lastName', typ: '' },
      { json: 'address1', js: 'address1', typ: '' },
      { json: 'address2', js: 'address2', typ: '' },
      { json: 'address3', js: 'address3', typ: '' },
      { json: 'postalCode', js: 'postalCode', typ: '' },
      { json: 'city', js: 'city', typ: '' },
      { json: 'countryIsoCode', js: 'countryIsoCode', typ: '' },
      { json: 'email', js: 'email', typ: '' },
      { json: 'privatePhone', js: 'privatePhone', typ: '' },
      { json: 'workPhone', js: 'workPhone', typ: '' },
      { json: 'mobilePhone', js: 'mobilePhone', typ: '' },
      { json: 'born', js: 'born', typ: null },
      {
        json: 'authorizedByCountryIsoCode',
        js: 'authorizedByCountryIsoCode',
        typ: null,
      },
      {
        json: 'writtenPermisionRequired',
        js: 'writtenPermisionRequired',
        typ: true,
      },
      { json: 'nationalJudge', js: 'nationalJudge', typ: true },
      { json: 'spokenLanguages', js: 'spokenLanguages', typ: a('any') },
      { json: 'breedAuthorisations', js: 'breedAuthorisations', typ: a('any') },
      {
        json: 'breedGroupAuthorisations',
        js: 'breedGroupAuthorisations',
        typ: a('any'),
      },
      { json: 'foreignKeys', js: 'foreignKeys', typ: a(r('ForeignKey')) },
      { json: 'removed', js: 'removed', typ: true },
      { json: 'name', js: 'name', typ: '' },
      { json: 'address', js: 'address', typ: '' },
    ],
    false
  ),
  ForeignKey: o(
    [
      { json: 'source', js: 'source', typ: '' },
      { json: 'value', js: 'value', typ: '' },
    ],
    false
  ),
}
