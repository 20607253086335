import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  InjectionToken,
  NgModule,
  Optional,
} from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppComponent } from './app.component'
import { RouterModule } from '@angular/router'
import { AppRoutingModule } from './app.routing.module'
import { AppCommonModule } from './app.common.module'
import { environment } from '../environments/environment'

import { FIREBASE_CONFIG } from 'ngfire'

import {
  initializeAppCheck,
  provideAppCheck,
  ReCaptchaV3Provider,
  CustomProvider,
} from '@angular/fire/app-check'
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app'
import {
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics'
import { FunctionsModule } from '@angular/fire/functions'

import type { app } from 'firebase-admin'
import { TuiRootModule } from '@taiga-ui/core'
import { ClarityModule } from '@clr/angular'
import { CdsModule } from '@cds/angular'
import { AngularFireModule } from '@angular/fire/compat'
import { ENVIRONMENT } from '@devent/web/environment'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import {
  InterceptorAppCheckService,
  InterceptorService,
} from '@devent/web/shared/services'
import {
  ApmModule,
  ApmService,
  ApmErrorHandler,
} from '@elastic/apm-rum-angular'

export const FIREBASE_ADMIN = new InjectionToken<app.App>('firebase-admin')

import '@cds/core/icon/register.js'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CommonModule } from '@angular/common'
import { FirebaseLoginModule } from '@devent/firebase-login'
import {
  getPerformance,
  initializePerformance,
  providePerformance,
  trace,
} from '@angular/fire/performance'
import { AngularFireAuthModule } from '@angular/fire/compat/auth'
import { LoginComponentModule } from './login.component'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ApmModule,
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    TuiRootModule,
    ClarityModule,
    CdsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    providePerformance(() => {
      const p = getPerformance(getApp())
      p.dataCollectionEnabled = true
      return p
    }),
    provideAppCheck(
      (injector) => {
        console.log('provideAppCheck', injector)
        const admin = injector.get<app.App | null>(FIREBASE_ADMIN, null)
        if (admin) {
          const provider = new CustomProvider({
            getToken: () =>
              admin
                .appCheck()
                .createToken(environment.firebase.appId, {
                  ttlMillis: 604_800_000 /* 1 week */,
                })
                .then(({ token, ttlMillis: expireTimeMillis }) => ({
                  token,
                  expireTimeMillis,
                })),
          })
          return initializeAppCheck(undefined, {
            provider,
            isTokenAutoRefreshEnabled: false,
          })
        } else {
          //FIREBASE_APPCHECK_DEBUG_TOKEN
          console.log(
            'provideAppCheck - no admin',
            environment.recaptcha3SiteKey
          )
          const provider = new ReCaptchaV3Provider(
            environment.recaptcha3SiteKey
          )
          return initializeAppCheck(undefined, {
            provider,
            isTokenAutoRefreshEnabled: true,
          })
        }
      },
      [new Optional(), FIREBASE_ADMIN]
    ),
    FunctionsModule,
    AppCommonModule,
    // TODO: remove when convertet
    /* AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule, */
    //FirebaseLoginModule,
    //LoginComponentModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    //PerforformanceService,
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorAppCheckService,
      multi: true,
    },
    { provide: FIREBASE_CONFIG, useValue: { options: environment.firebase } },
    ApmService,
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler,
    },
    /* { provide: ErrorHandler, useClass: GlobalErrorHandler }, */
  ],
  bootstrap: [],
})
export class AppModule {
  constructor(service: ApmService) {
    console.log('AppModule.ctor')
    // Agent API is exposed through this apm instance
    const apm = service.init({
      serviceName: 'devent-app',
      serverUrl:
        'https://d5f4915a0b904d5d8830040fb4fcc5fb.apm.europe-west3.gcp.cloud.es.io:443',
      logLevel: 'debug',
      serviceVersion: environment.version,
      environment: environment.production ? 'production' : 'development',
      distributedTracingOrigins: [
        'https://devent.no',
        /https?:\/\/devent\.no:\d{4}/,
      ],
    })

    apm.setUserContext({
      username: 'Mr Devent',
      id: '#007',
    })
  }
}
