import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import {
  getRemoteConfig,
  provideRemoteConfig,
} from '@angular/fire/remote-config'
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics'
import { getMessaging, provideMessaging } from '@angular/fire/messaging'
import { getApp } from '@angular/fire/app'

import { AppModule } from './app.module'
import { AppComponent } from './app.component'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment'
import { BrowserTransferStateModule } from '@angular/platform-browser'
import { provideAuth } from '@angular/fire/auth'

import {
  initializeAuth,
  browserPopupRedirectResolver,
  indexedDBLocalPersistence,
} from '@angular/fire/auth'
import { connectAuthEmulatorInDevMode } from './emulators'
import { FirebaseLoginModule } from '@devent/firebase-login'
import {
  PwaUpdaterLogService,
  PwaUpdaterCheckService,
  PwaUpdaterErrorService,
  PwaUpdaterPromptService,
} from '@devent/web/shared/services'

/**
 * Note: clarity
 * Some Angular projects are setup using Angular Feature Modules
 * (opens new window), which is a way of splitting the application
 *  into smaller units. If you do this, you'll want to ensure you
 * add ClarityModule and BrowserAnimationsModule to each feature
 * module imports array, or you might also be able to add it once
 * to a shared library module (opens new window).
 */

@NgModule({
  imports: [
    AppModule,
    BrowserTransferStateModule,
    provideRemoteConfig(() => {
      console.log('Remote config loaded')
      const rc = getRemoteConfig()
      return rc
    }),
    provideAnalytics(() => {
      console.log('Analytics loaded')
      const a = getAnalytics()
      return a
    }),
    provideMessaging(() => {
      console.log('Messaging loaded')
      const m = getMessaging()
      return m
    }),
    provideAuth(() => {
      console.log('Auth loaded')
      const auth = initializeAuth(getApp())
      /* const auth = initializeAuth(getApp(), {
        persistence: indexedDBLocalPersistence,
        popupRedirectResolver: browserPopupRedirectResolver,
      }) */
      //connectAuthEmulatorInDevMode(auth)
      return auth
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production || environment.dev,
      //registrationStrategy: 'registerWhenStable:30000',
      registrationStrategy: 'registerWithDelay:2000',
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    PwaUpdaterLogService,
    /* PwaUpdaterCheckService,
    PwaUpdaterErrorService,
    */
    PwaUpdaterPromptService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppBrowserModule {
  constructor(
    private readonly _pwaUpdaterLog: PwaUpdaterLogService,

    //private readonly _pwaUpdaterCheck: PwaUpdaterCheckService,
    //private readonly _pwaUpdaterError: PwaUpdaterErrorService,
    private readonly _pwaUpdaterPrompt: PwaUpdaterPromptService
  ) {
    console.log('AppBrowserModule constructor')
  }
}
