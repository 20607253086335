import { ApplicationRef, Injectable } from '@angular/core'
import { SwUpdate } from '@angular/service-worker'
import { concat, interval } from 'rxjs'
import { first } from 'rxjs/operators'

function notifyUser(msg) {
  alert(msg)
}

@Injectable()
export class PwaUpdaterErrorService {
  constructor(updates: SwUpdate) {
    console.log('PwaUpdaterErrorService.ctor')
    updates.unrecoverable.subscribe((event) => {
      notifyUser(
        'An error occurred that we cannot recover from:\n' +
          event.reason +
          '\n\nPlease reload the page.'
      )
    })
  }
}
