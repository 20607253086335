export enum AgilitySizes {
  XS = 'Extra Small (XS)',
  S = 'Small (S)',
  M = 'Medium (M)',
  L = 'Large (L)',
  XL = 'Extra Large (XL)',
}

export enum AgilityLevels {
  KL1 = 'Klasse 1',
  KL2 = 'Klasse 2',
  KL3 = 'Klasse 3',
}

export enum AgilityOfficialTypes {
  A = 'Agility',
  H = 'Hopp',
  FA = 'Finale Ag.',
  FH = 'Finale Hopp',

  AL = 'Lag - Ag.',
  HL = 'Lag - Hopp',
  FHL = 'Finale Lag Hopp',
  FAL = 'Finale Lag Ag.',

  ASL = 'Stafett - Ag.',
  HSL = 'Stafett - Hopp',
  FSHL = 'Finale Stafett Hopp',
  FSAL = 'Finale Stafett Ag.',
}

export enum AgilityUnofficialTypes {
  B = 'Blåbær',

  UH = 'Åpen Hopp',
  UA = 'Åpen Agility',
  UF = 'Åpen Finale',

  UHK = 'Uoff. Hopp (m/KL)',
  UAK = 'Uoff. Ag. (m/KL)',

  UAKF = 'Uoff. Finale Ag. (m/KL)',
  UHKF = 'Uoff. Finale H (m/KL)',

  UHL = 'Uoff. Lag - Hopp',
  UAL = 'Uoff. Lag - Ag.',

  UFHL = 'Uoff. Finale Lag Hopp',
  UFAL = 'Uoff. Finale Lag Ag.',

  UASL = 'Uoff. Stafett - Ag.',
  UHSL = 'Uoff. Stafett - Hopp',

  UFSHL = 'Uoff. Finale Stafett Hopp',
  UFSAL = 'Uoff. Finale Stafett Ag.',
}

export const typesWithClasses = [
  'A',
  'H',
  'FA',
  'FH',
  'UHK',
  'UAK',
  'UAKF',
  'UHKF',
]
export const typesWithTries = ['B', 'UH', 'UA']

export const hasClasses = (type: string) => typesWithClasses.includes(type)
export const hasTries = (type: string) => typesWithTries.includes(type)

// TODO: read keys of the types to get all with F - DO WE NEED THIS?
export const isFree = (type: string) =>
  [
    'FA',
    'FH',
    'FAL',
    'FHL',
    'UAKF',
    'UHKF',
    'UF',
    'UFASL',
    'UFHSL',
    'UFAL',
    'UFHL',
  ].includes(type)

// TODO: read keys of the types to get all with F
export const isFinale = (type: string) =>
  [
    'FA',
    'FH',
    'FAL',
    'FHL',
    'UAKF',
    'UHKF',
    'UF',
    'UFASL',
    'UFHSL',
    'FASL',
    'FHSL',
  ].includes(type)

// TODO: read keys of the types to get all with L
export const isTeamContest = (type: string) =>
  [
    'AL',
    'HL',
    'ASL',
    'HSL',
    'FAL',
    'FHL',
    'UHL',
    'UAL',
    'UASL',
    'UHSL',
    'UFASL',
    'UFHSL',
    'FASL',
    'FHSL',
  ].includes(type)
