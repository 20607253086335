import { AppCheck, getToken } from '@angular/fire/app-check'
import { Inject, Injectable } from '@angular/core'
import { HttpInterceptor } from '@angular/common/http'
import { HttpRequest } from '@angular/common/http'
import { from, lastValueFrom, Observable } from 'rxjs'
import { HttpHandler } from '@angular/common/http'
import { HttpEvent } from '@angular/common/http'
import { ENVIRONMENT, Environment } from '@devent/web/environment'
@Injectable({
  providedIn: 'root',
})
export class InterceptorAppCheckService implements HttpInterceptor {
  token: string
  ignoreCallsStartingWith = ['assets']
  /**
   * This is a list of api's that we want to skip the interceptor for.
   * europe-  : firebase functions
   * ds/login : login (services)
   * ds/results : public results (services)
   * ds/events : public results events (services)
   */
  omitCallsIncluding = ['europe-', 'ds/login', 'ds/results', 'ds/events']
  skipInterceptor = false
  ignoreInterceptor = false
  // NOTE: had to remove versionservice, since this will cause cyclic dependency, so services that are intercepted can not be used here

  constructor(
    @Inject(ENVIRONMENT) private readonly _env: Environment,
    private readonly appCheck: AppCheck
  ) {
    console.log('-- AppCheck Interceptor created')
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.skipInterceptor = false
    /* this.omitCallsIncluding.forEach((api) => {
      // console.log('checking', api, req.url, req.url.indexOf(api))
      if (req.url.includes(api)) {
        this.skipInterceptor = true
      }
    }) */

    this.ignoreInterceptor = false
    this.ignoreCallsStartingWith.forEach((api) => {
      if (req.url.startsWith(api)) {
        this.ignoreInterceptor = true
      }
    })

    if (this.ignoreInterceptor) {
      console.log('ignoring -> ' + req.url)
      return next.handle(req)
    }

    if (this.skipInterceptor) {
      console.log('headers (skipInterceptor): ', req.headers)
      return next.handle(req)
    } else {
      return from(this.handleAppCheck(req, next))
    }
  }

  async handleAppCheck(req: HttpRequest<any>, next: HttpHandler) {
    const acTokenResponse = await getToken(this.appCheck, false)
    const acReq = req.clone({
      setHeaders: {
        'X-Firebase-AppCheck': acTokenResponse.token,
      },
    })
    console.log('headers (acToken): ', acReq?.headers)

    return lastValueFrom(next.handle(acReq)) //.toPromise()
  }
}
