import { Inject, Injectable } from '@angular/core'
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { ENVIRONMENT, Environment } from '@devent/web/environment'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImRlIiwic3ViIjozLCJpYXQiOjE2MTA2MDgzNTUsImV4cCI6MTYxMDY0NDM1NX0.5_aSlZ3paNo6iCdX7viNOsD2gjK_e9Vp3XX_LEFonW0',
  }),
}
@Injectable({
  providedIn: 'root',
})
export class VippsPaymentService {
  constructor(
    private readonly http: HttpClient,
    @Inject(ENVIRONMENT) private readonly environment: Environment
  ) {}

  /*
  wait for callback and data from vipps, have to be synced/cancelled if we get the callback from server

  pollUntillCompleted() {
    const subscription = interval(5000)
      .pipe(
        switchMap(() =>
          this.apiService.getStatus()
        ),
        takeWhile(({ status }) => {
          return !this.isStatusCompleted(status);
        })
      )
      .subscribe(
        _ => {},
        _ => {},
        () => {
          this.isStatusCheckRunning = false;
          this.apiService.reloadData();
        }
      );
  }
  */

  public paymentToken(): Observable<any> {
    return this.http
      .get(this.environment.servicesUrl + 'ds/payment/token', httpOptions)
      .pipe(catchError(this.handleError))
  }
  public initPayment(orderInfo): Observable<any> {
    console.log('Init payment', orderInfo)
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/payment/init',
        orderInfo,
        httpOptions
      )
      .pipe(
        tap((t) => console.log('init tap', t)),
        catchError(this.handleError)
      )
  }
  public initPaymentV2(orderInfo): Observable<any> {
    console.log('Init payment V2', orderInfo)
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/payment/init-v2',
        orderInfo,
        httpOptions
      )
      .pipe(
        tap((t) => console.log('init v2 tap', t)),
        catchError(this.handleError)
      )
  }
  public refundPayment(orderId, orderInfo): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/payment/refund/' + orderId,
        orderInfo,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }
  public refundPaymentV2(refundInfo): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/payment/refund-v2/',
        refundInfo,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }
  public capturePayment(orderId, orderInfo): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/payment/capture/' + orderId,
        orderInfo,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }
  public capturePaymentV2(captureInfo): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/payment/capture-v2/',
        captureInfo,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }
  public cancelPayment(orderId, orderInfo): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/payment/cancel/' + orderId,
        orderInfo,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }
  public cancelPaymentV2(orderId, orderInfo): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/payment/cancel-v2/' + orderId,
        orderInfo,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public paymentDetails(
    orderId: string,
    token: string,
    skipDbUpdate = false
  ): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/payment/details/' + orderId,
        { access_token: token, skipDbUpdate },
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public paymentDetailsV2(orderId: string, token: string): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/payment/details-v2/' + orderId,
        { access_token: token },
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public paymentDetailsExtended(
    orderId: string,
    token: string
  ): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl +
          'ds/payment/details/' +
          orderId +
          '/extended',
        { access_token: token },
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public paymentDetailsStripe(orderId: string): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/stripe-payment/details/' + orderId,
        {},
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public paymentCaptureStripe(orderId: string): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/stripe-payment/capture/' + orderId,
        {},
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public paymentCancelStripe(orderId: string): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/stripe-payment/cancel/' + orderId,
        {},
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  public paymentRefundStripe(refundInfo): Observable<any> {
    return this.http
      .post(
        this.environment.servicesUrl + 'ds/stripe-payment/refund/',
        refundInfo,
        httpOptions
      )
      .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message)
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      )
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.')
  }
}
