import {
  ApplicationRef,
  Component,
  Inject,
  isDevMode,
  LOCALE_ID,
} from '@angular/core'
import {
  ClarityIcons,
  barsIcon,
  cloudIcon,
  cogIcon,
  homeIcon,
  shoppingCartIcon,
  userIcon,
  angleIcon,
} from '@cds/core/icon'
import { APP_BASE_HREF } from '@angular/common'
import {
  Subject,
  Observable,
  of,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs'

import { environment } from '../environments/environment'

@Component({
  selector: 'de-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent {
  title = 'Devent'

  data$: any
  vippsLoginUrl = environment.servicesUrl + 'ds/login/start'
  noUser$ = new Subject<boolean>()
  collapsed = true
  VERSION = '1.0.1'
  production = environment.production
  emulators = environment.emulators
  version$: Observable<any>
  systemAlert$: Observable<any>
  systemInfo$: Observable<any>
  applicationInfo$: Observable<any>
  updatesAvailable: Observable<{
    type: string
    current: { hash: string; appData?: object }
    available: { hash: string; appData?: object }
  }>

  readonly change$: Observable<any>
  readonly ac_change$: Observable<any>

  constructor(
    @Inject(APP_BASE_HREF) public hrefBase: string,
    @Inject(LOCALE_ID) public locale: string,
    appRef: ApplicationRef
  ) {
    if (isDevMode()) {
      appRef.isStable
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((it) => {
          console.log('isStable', it)
        })
    }
    // this.applicationInfo$ = of({ message: 'hei ehi' })
    ClarityIcons.addIcons(
      userIcon,
      barsIcon,
      shoppingCartIcon,
      userIcon,
      cloudIcon,
      cogIcon,
      homeIcon,
      angleIcon
    )
    ClarityIcons.addIcons([
      'dog',
      '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="dog" class="svg-inline--fa fa-dog fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M298.06,224,448,277.55V496a16,16,0,0,1-16,16H368a16,16,0,0,1-16-16V384H192V496a16,16,0,0,1-16,16H112a16,16,0,0,1-16-16V282.09C58.84,268.84,32,233.66,32,192a32,32,0,0,1,64,0,32.06,32.06,0,0,0,32,32ZM544,112v32a64,64,0,0,1-64,64H448v35.58L320,197.87V48c0-14.25,17.22-21.39,27.31-11.31L374.59,64h53.63c10.91,0,23.75,7.92,28.62,17.69L464,96h64A16,16,0,0,1,544,112Zm-112,0a16,16,0,1,0-16,16A16,16,0,0,0,432,112Z"></path></svg>',
    ])
  }
}
